<template>
  <div class="about">
    <h1>This is a Variazioni page</h1>
    <p>
      We're no strangers to love <br>
      You know the rules and so do I (do I) <br>
      A full commitment's what I'm thinking of <br>
      You wouldn't get this from any other guy <br>
    </p>
    <p>
      I just wanna tell you how I'm feeling <br>
      Gotta make you understand <br>
    </p>
    <p>
      <b>
        Never gonna give you up <br>
        Never gonna let you down <br>
        Never gonna run around and desert you <br>
        Never gonna make you cry <br>
        Never gonna say goodbye <br>
        Never gonna tell a lie and hurt you <br>
      </b>
    </p>
  </div>
</template>
