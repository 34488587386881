<script>
  export const funFacts = [
          "Il nome Wendy è stato creato per il libro 'Peter Pan'.",
          "Il nome completo di Barbie è Barbara Millicent Roberts.",
          "Ogni volta che lecchi un francobollo, consumi 1/10 di caloria.",
          "In media ci si addormenta in 7 minuti.",
          "Alle renne piacciono le banane.",
          "Mel Blanc (la voce originale di Bugs Bunny) era allergico alle carote.",
          "A causa della rotazione della Terra, un oggetto può venire lanciato più lontano se viene lanciato a ovest.",
          "In media si passano 6 mesi della propria vita fermi a un semaforo rosso.",
          "Ogni anno vengono stampati più soldi del Monopoly che soldi veri.",
          "La Caesar Salad non ha niente a che fare con Giulio Cesare. E' stata creata negli anni '20 in un bar in Tijuana, Mexico",
          "Un quarto delle ossa nel tuo corpo sono nei tuoi piedi.",
          "Il 10% delle entrate del governo russo proviene dalla vendita di vodka.",
          "Mezzo chilo di sacchetti di patatine constano 200% in più di mezzo chilo di patate.",
          "Una giraffa può passare più tempo senz'acqua di un cammello.",
          "Circa l'80% di tutti gli animali sulla Terra hanno 6 zampe.",
          "Il 90% di tutte le specie che si sono estinte erano uccelli.",
          "C'è circa un pollo per ogni essere umano nel mondo.",
          "Ognuno di noi produce 1.5 Kg di spazzatura al giorno, per la maggior parte carta.",
          "Gli arcobaleni possono essere visti solo la mattina o nel tardo pomeriggio. Possono esserci solo quando il sole è 40° o meno sopra l'orizzonte.",
          "A Calama, una città in Cile nel deserto di Atacama, non ha MAI piovuto.",
          "Comprare una macchina oggi costa di più di quanto ha speso Cristoforo Colombo per tre viaggi verso le Americhe.",
          "L'Ora Legale non viene usata in buona parte dell'Arizona e dell'Indiana.",
          "Le formiche hanno comportamenti simili agli umani: Quando si svegliano, si stiracchiano e sembrano sbadigliare, per poi iniziare la loro giornata.",
          "Le api hanno 5 occhi: 3 piccoli sopra la loro testa e 2 più grandi davanti.",
          "1/4 della popolazione mondiale vive con meno di $200 all'anno.",
          "90 milioni di persone vivono con meno di $75 all'anno.",
          "Le farfalle sentono i sapori con le loro zampe posteriori",
          "Solo le zanzare femmina pungono, e sono attratte dal blu più di qualsiasi altro colore.",
          "E' illegale cacciare cammelli in Arizona.",
          "Non ci sono orologi nei casinò di Las Vegas.",
          "Il primo prodotto ad avere un codice a barre sono state le gomme da masticare Wrigley's.",
          "Quando starnutisci, aria ed altre particelle escono dalle narici a una velocità di oltre 160 Km/h",
          "Non si riesce a tenere gli occhi aperti quando starnutisci perchè in quell'istante tutte le funzioni del corpo si fermano, incluso il cuore.",
          "La crescita annuale del traffico web è del 314.000%",
          "Il sedano ha calorie negative! Le calorie del sedano sono più basse di quante se ne bruciano masticandolo.",
          "In media una matita può scrivere per 55 Km.",
          "Il 'POP!' che senti quando ti scrocchi le dita è in realtà una piccola bolla di gas che esplode.",
          "Bruci più calorie dormendo che guardano la TV.",
          "Frederic Chopin, compositore e pianista, aveva la barba solo da un lato. Disse: 'Non Importa, tanto il pubblico mi vede solo di profilo!'",
          "I bambini ridono in media 300 volte al giorno, gli adulti solo 17.",
          "La guerra più corta del mondo è stata tra Zanzibar e l'Inghilterra nel 1896. Zanzibar si arrese dopo 38 minuti.",
          "Siamo nati con 300 ossa, ma da adulti se ne abbiamo solo 206.",
          "Se diventi cieco da un occhio perdi solo 1/5 della tua visione ma tutta la tua percezione della profondità.",
          "Le donne battono le palpebre il doppio degli uomini.",
          "Il muscolo più forte (relativamente alla sua dimensione) è la lingua.",
          "L'apertura alare di un Boeing 747 è più lunga del primo volo dei fratelli Wright.",
          "L'American Airlines risparmiò $40.000 nel 1987 togliendo una singola oliva da ogni insalata servita in prima classe.",
          "Gli occhi non crescono mai, ma il naso e le orecchie non smettono mai di crescere.",
          "'Tom Sawyer' è stato il primo romanzo scritto su una macchina da scrivere.",
          "Ci sono 1 milione di formiche per ogni essere umano nel mondo.",
          "La metà dei cibi più mangiati oggi nel mondo sono nati nelle montagne delle Ande (patate, mais, fagioli, arachidi, fragole, e molti altri).",
          "Il logo di McDonald's è più riconosciuto nel mondo della croce cristiana.",
          "Nel mondo accadono 50.000 terremoti all'anno.",
          "La terra viene colpita da fulmini 100 volte al secondo",
          "In media, gli americani mangiano 18 acri di pizza ogni giorno.",
          "Negli ultimi 3500 anni, ci sono stati approssimativamente solo 230 di pace.",
          "In media, durante la sua vita, una persona passa 2 anni al telefono.",
          "La Coca-Cola in origine era verde.",
          "Le persone intelligenti hanno più zinco e rame nei capelli.",
          "111,111,111 x 111,111,111 = 12,345,678,987,654,321",
          "Il più lungo volo di un pollo mai registrato è di 13 secondi.",
          "Gli emu e i canguri non riescono a camminare all'indietro. Per questo motivo sono raffigurati sullo stemma Autraliano.",
          "I gatti hanno oltre 100 suoni vocali, i cani all'incirca 10.",
          "I campi da golf coprono circa il 4% del Nord America.",
          "Il valore di Pi greco è stato ufficialmente arrotondato da 3.14159265359 a 3.14 il 31 Dicembre 1999.",
          "Un foglio di carta non può essere piegato più di 7 volte.",
          "Tra il 25% e il 33% della popolazione starnutisce quando esposta alla luce del sole.",
          "La montagna Olympus Mons su Marte è grande 3 volte l'Everest.",
          "Ogni giorno sulla terra cadono oltre 900 Kg di detriti e polvere stellare.",
          "Ogni mese c'è almeno una segnalazione di avvistamenti UFO in ogni provincia del Canada.",
          "Bisogna leccare circa 142,18 volte un lecca-lecca per raggiungerne il centro.",
          "Un cubo di Rubix può venire risolto in soli 17 passaggi.",
          "Ci vogliono 1575 scalini per raggiungere la cima dell' Empire State building.",
          "Nella giungla delle Filippine, lo yo-yo veniva usato come arma.",
          "In Paraguay è legale duellare ma sono se entrambi i partecipanti sono donatori di sangue.",
          "Il Texas è l'unico stato autorizzato a tenere la sua bandiera alla stessa altezza di quella degli USA.",
          "Masticare una gomma mentre tagli le cipolle ti aiuterà a non piangere.",
          "Un colpo di frusta è un piccolo boom sonico.",
          "Giove è più grande di tutti gli altri pianeti del Sistema Solare combinati.",
          "L'acqua calda è più pesante di quella fredda.",
          "I neonati nascono senza rotule. Non compaiono prima dei 2-6 anni di età.",
          "I Meteorologi dicono di essere accurati all'85%",
          "Il nome completo di Los Angeles è 'El Pueblo de Nuestra Señora la Reina de Los Angeles de Porciuncula'.",
          "Le galline depongono più uova quando ascoltano musica pop.",
          "La capacità di memoria del cervello umano supera i 4 Terabyte.",
          "Nel Vermont, il rapporto tra mucche e persone è 10:1",
          "Nello spazio qualsiasi liquido si formerà in una sfera, a causa della sua tensione di superficie.",
          "In media, nella sua vita una persona cammina abbastanza da percorrere 5 volte l'equatore.",
          "Shakespeare scriveva il suo nome in molti modi diversi.",
          "Virginia Woolf scrisse tutti i suoi libri stando in piedi.",
          "Einstein non riusciva a parlare fluentemente fino ai 9 anni.",
          "Puoi navigare intorno al mondo senza fermarti seguendo le coordinate Latitudine 60 gradi Sud.",
          "La terra pesa circa 6,588,000,000,000,000,000,000,000,000 tonnellate.",
          "I porcospini galleggiano in acqua.",
          "Lo squalo è l'unico pesce che può battere le palpebre con tutti e due gli occhi.",
          "Le mandorle fanno parte della famiglia delle pesche.",
          "Le tigri non hanno le strisce solo sulla pelliccia, ma anche sulla pelle",
          "Nelle pubblicità, spesso l'orario sugli orologi è 10:10.",
          "Le libellule hanno una vita media di 24 ore.",
          "Le lumache possono dormire per 3 anni.",
          "Tutti gli orsi polari sono mancini.",
          "In Cina ci sono più persone che parlano inglese che negli USA.",
          "Gli elefanti sono gli unici animali che non riescono a saltare.",
          "Febbraio 1865 è l'unico mese in cui non c'è stata una luna piena.",
          "Se urlassi per 8 anni, 7 mesi e 6 giorni, produrresti abbastanza energia per farti un caffè.",
          "Negli ultimi 4000 anni, nessun nuovo animale è stato addomesticato.",
          "Leonardo Da Vinci inventò le forbici.",
          "Le stelle marine non hanno il cervello.",
          "I nomi di tutti i continenti iniziano e finiscono con la stessa lettera.",
          "Il nome più lungo di un posto: Taumatawhakatangihangakoauauotamateaturipukakapikimaungahoronukupokaiakitnatahu, una collina in Nuova Zelanda.",
          "L'occhio di uno struzzo è più grande del suo cervello.",
          "Alfred Hitchcock non aveva l'ombelico, a causa di una operazione.",
          "Negli scacchi la parola 'Scacco matto' viene dal persiano 'Shah Mat', ovvero 'Il Re è morto'",
          "2/3 di tutte le melanzane nel mondo vengono coltivate nel New Jersey.",
          "Il calamaro gigante ha gli occhi più grandi del mondo.",
          "Le giraffe non hanno corde vocali.",
          "Van Gogh ha venduto solo un quadro nella sua vita.",
          "Gli asini possono vedersi tutte e quattro le zampe contemporaneamente.",
          "La durata media di una ciglia è di 5 mesi.",
          "I ragni hanno il sangue trasparente.",
          "I cavalli possono guardare davanti con un occhio e indietro con l'altro.",
          "Shakespeare è citato 33,150 volte nell'Oxford English dictionary.",
          "10.000 mucche passano per l'aeroporto di Amsterdam ogni anno.",
          "Venere è l'unico pianeta che gira in senso orario.",
          "Le querce non producono ghiande prima dei 50 anni.",
          "Il Re di Cuori è l'unico senza baffi.",
          "Le perle si sciolgono nell'aceto.",
          "Gli umani sono gli unici primati a non avere pigmenti nei palmi delle mani.",
          "Le impronte digitali dei koala e degli umani sono quasi indistinguibili.",
          "La prima canzone su MTV è stata 'Video Killed The Radio Star' dei the Buggles.",
          "I gatti hanno 32 muscoli in ogni orecchio.",
          "Il quack delle papere non fa eco, e nessuno sa perchè.",
          "I cammelli hanno tre palpebre.",
          "I delfini dormono con un occhio aperto.",
          "I gufi sono tra i pochi uccelli che possono vedere il colore blu.",
          "Le meduse sono 95% acqua",
          "I pipistrelli girano sempre a sinistra quando escono da una caverna.",
          "La Spagna è la più grande produttrice di sughero del mondo",
          "Ci sono 1792 scalini nella torre Eiffel",
          "Ci sono 269 scalini per raggiungere la cima della torre di Pisa"
        ]

  export const funnyPhrases = [
    "Generando frasi divertenti...",
    "Scambiando spazio e tempo...",
    "Girando violentemente sull'asse Y...",
    "Piegando cucchiai...",
    "Non pensare agli ippopotami viola...",
    "Abbiamo bisogno di un nuovo fusibile...",
    "Buongiorno... e casomai non vi rivedessi, buon pomeriggio, buonasera e buonanotte!",
    "Che la forza sia con te...",
    "Gli architetti stanno ancora facendo le bozze",
    "Stiamo costruendo tutto il più velocemente possibile!",
    "Ordini un primo o un secondo?",
    "...musica d'ascensore...",
    "Per favore, aspetta mentre gli elfi disegnano la tabella",
    "Non preoccuparti, alcuni prodotti hanno cercato di scappare, ma li abbiamo riacciuffati!",
    "Vuoi anche delle patatine?",
    "Caffè?",
    "Controllando la costante gravitazionale della tua locazione...",
    "Trattieni il respiro!",
    "Canticchia qualcosa!",
    "Hai tagliato i capelli?",
    "Il server è alimentato da un limone e due elettrodi",
    "Stiamo testando la tua pazienza...",
    "Stiamo seguendo il bianconiglio nella sua tana...",
    "Perchè non ordini un panino?",
    "Il satellite si sta spostando nella tua posizione...",
    "I prodotti si spostano lentamente oggi...",
    "Scava sulla 'X' per il tesoro nascosto. ARRR!",
    "(Inserire gettone)",
    "Siamo arrivati?",
    "Vieni spesso qui?",
    "Perchè così serio?",
    "Non sei tu. Sono io.",
    "Contando all'indietro a partire da Infinito...",
    "NIENTE PANICO!!! AAAAAAAAAAAH!!!",
    "Non correre! Siamo tuoi amici!",
    "ATTENZIONE: Non darti fuoco.",
    "Stiamo preparando dei biscotti...",
    "Vuoi un biscotto?",
    "Creando un campo di inversione del ciclo temporale...",
    "Girando la ruota della fortuna...",
    "Tirando fuori il coniglio dal cappello...",
    "Era questa la tua carta? Ah no?",
    "Calcolando le probabilità di successo...",
    "Mi sento come se dovessi caricare qualcosa... Ah giusto!",
    "Come chiami 8 Hobbit? Un Hobbyte.",
    "Avrei dovuto usare un linguaggio compilato...",
    "Impostando il condensatore di flusso...",
    "Per favore, aspettare che i bradipi inizino a muoversi.",
    "Non rompere lo schermo! Almeno, non ancora!",
    "Giuro che ha quasi finito.",
    "Prendiamoci un minutino di relax...",
    "C'è un unicorno alla fine, lo giuro.",
    "Tenendo tutti gli 1 e cancellando tutti gli 0...",
    "Mettendo la ciliegina sulla torta...",
    "Pulendo via le ragnatele...",
    "Mettendo i puntini sulle i...",
    "Arrampicandosi sugli specchi...",
    "Dove è finito internet?",
    "Ti concedo 3 desideri da esprimere... (non puoi desiderare un caricamento più veloce)",
    "Il tempo vola quando ci si diverte!",
    "Prenditi un caffè e torna tra un po'... No, scherzo, dai",
    "I criceti stanno correndo nelle loro ruote...",
    "Shhh! Ascolta...",
    "Tu non puoi passare! Almeno, non ancora...",
    "Convincendo le Intelligenze Artificiali a non diventare malvagie...",
    "Il tuo pollice sinistro punta a destra e il tuo pollice destro punta a sinistra.",
    "Come sei finito qui???",
    "Calcolando la risposta alla domanda fondamentale sulla vita, l'universo e tutto quanto...",
    "Luke, sono tuo padre!",
    "La vita è come una scatola di cioccolatini, non sai mai quello che ti capita",
    "Mi piace il mio lavoro solo quando sono in vacanza...",
    "Non sono pigro, sono solo rilassato!",
    "Ogni volta che trovo la chiave per il successo, qualcuno cambia lucchetto.",
    "Io penso, ergo sono. Almeno penso.",
    "Costruendo i muri portanti...",
    "Non siamo responsabili di schermi rotti causati dall'aver aspettato troppo.",
    "Se cerchi 'Google' su Google puoi rompere internet",
    "Non sapevo che la vernice si asciugasse così in fretta...",
    "Non sapevo che l'erba crescesse così in fretta...",
    "Faccio fare un giro al cane, vieni anche tu?",
    "Dividendo per zero...",
    "Se non torno tra 5 minuti, aspetta di più!.",
    "Crackando crittografia di livello militare...",
    "Simulando il problema del commesso viaggiatore...",
    "Provando P=NP...",
    "Girando i pollici...",
    "Provando a ordinare con una complessità di O(n)...",
    "Cercando senso dello humour, attendere per favore...",
    "Attendere per favore, mentre lo stagista ci prepara i caffè...",
    "Cambiando framework JS...",
    "Scusami, ero distratto da foto di gattini",
    "DA FARE: Inserire dialoghi divertenti",
    "Speriamo ne valga la pena!",
    "Ordinando gli 1 e gli 0...",
    "Attendere per favore, sto leggendo il manuale",
    "Caricando messaggio divertente...",
    "Sentiti libero di girare sulla tua sedia",
    "Hai detto qualcosa?",
    "Inizializzando l'inizializzatore...",
    "Ottimizzando l'ottimizzatore...",
    "Scaricando lo scaricatore...",
    "Aggiornando l'aggiornatore...",
    "Mai far sapere a un computer quando sei di fretta!",
    "Un computer fa quello che gli dici, ma quello che fa potrebbe essere molto diverso da quello che avevi in testa.",
    "Ci sono alcune cose che l'uomo non potrà mai sapere... Per tutto il resto c'è Google!",
    "Spalando carbone nel server...",
    "Ordinando i pixels...",
    "Hai visto che tempo, eh?",
    "Dicono che domani dovrebbe piovere, ma io non ci credo...",
    "Costruendo i muri...",
    "Tutto nell'universo è una patata oppure non una patata.",
    "La gravità del tuo problema è sempre minore di quanto credi.",
    "Correndo con le forbici...",
    "Caffè da me, domani alle 10. Non fare tardi!",
    "Lavoro, lavoro...",
    "mumble... mumble... mumble... mumble... mumble... mumble... mumble...",
    "Pazienza! E' difficile, sai?",
    "Inventando nuovi modi per farti aspettare...",
    "Dunque... Ti ho fatto vedere le foto delle mie vacanze?",
    "DA FARE: Inserire musica d'ascensore",
    "Siamo comunque più veloci degli aggiornamenti di Windows...",
    "Stiamo lavorando sodo, davvero!",
    "Dando da mangiare agli unicorni...",
    "Rompendo al barriera del subspazio...",
    "Creando una reazione anti-temporale...",
    "Bypassando il controllo dell'integratore di anti-materia...",
    "Invertendo la polarità degli scudi...",
    "Interrompendo i campi di curvatura con un'esplosione gravitonica inversa...",
    "Ti piace la rotellina di caricamento? L'ho fatta con le mie manine!",
    "Wow, guarda come gira!",
    "Sì, sono sveglio. Sto solo riposando gli occhi",
    "Controllando che gli gnomi siano ancora bassi...",
    "Hai spento il gas?",
    "Hai tirato il freno a mano?",
    "Hai spento i fari?",
    "Stai parlando con me?",
    "Il mio nome è Lin, Mar-lin",
    "Caricamenti? Dove stiamo andando non c'è bisogno di caricamenti!",
    "Metto la cera, tolgo la cera...",
    "Io non sono lento, è che mi programmano così...",
    "Verso l'infinito e oltre!",
    "Specchio specchio delle mie brame...",
    "Il mio tessssssoro!",
    "Uno stregone non è mai in ritardo, nè in anticipo, arriva precisamente quando intende farlo!",
    "Ho visto cose che voi umani non potreste immaginarvi...",
    "Al mio segnale, scatenate l'inferno",
    "No! Provare no. Fare! O non fare. Non c'è provare!",
    "Potrebbe andare peggio... Potrebbe piovere!",
    "'Io sono ineluttabile!' E io... sono... Marlin!",
    "Da grandi poteri derivano grandi responsabilità!",
    "Hasta la vista, baby!",
    "Yippee ki yay!",
    "1, 1, 2, 3, 5, 8, 13, 21...",
    "01001110 01101111 01101110 00100000 01110011 01101001 00100000 01110011 01100010 01101001 01110010 01100011 01101001 01100001 00100001",
    "-. --- -. / ... .. / ... -... .. .-. -.-. .. .- -.-.--",
    "I topi non avevano nipoti (leggilo al contrario)",
    "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
  ]

  export const barzellette = [
    "'Mi rifiuto!' disse il netturbino.",
    "Le mie figlie hanno sposato due salumieri. Quindi ho due... generi alimentari!",
    "Due mandarini litigano furiosamente e uno dice all'altro: 'guarda che ti spicchio!!",
    "Ma d'inverno si leggono più libri perché hanno la copertina?",
    "Cosa fa una fabbrica di carta igienica che fallisce? Va a rotoli.",
    "Cosa fa una fabbrica di carta igienica che fallisce? Va a rotoli.",
    "Ragazzo scoppia di salute. Feriti i genitori.",
    "Perché il pomodoro non riesce mai a dormire? Perché l'insalata russa!",
    "Qual è il colmo per due divorziati americani? Essere… stati uniti.",
    "Come si chiamano i boy-scout che vanno in macchina? Le giovani marmitte!",
    "Qual è il bambino che non ha paura del buio? Quello con la candela al naso!",
    "Che cosa fa un gallo in chiesa? Il chicchirichetto!",
    "Qual è il colmo per una giraffa? Essere nei guai fino al collo!",
    "Cosa deve fare un mago che non conosce né il presente, né il passato, né il futuro? Imparare meglio i verbi!",
    "'Pronto, centralino?' 'Lino c'entra, se ci stringiamo un pò!'",
    "Quando piange un pero? Quando è dis... perato!",
    "Abbiamo riso abbastanza, adesso pasta!",
    "C'è una mela ed una arancia che giocano a carte, la mela dice all'arancia: 'forza, pesca!'.",
    "Ho finito le battute che avevo in serbo; ora comincio con quelle in croato.",
    "La banana alla papaya: 'Conosci un buon avocado?'",
    "Quali sono le bibite preferite dagli elettricisti? Acqua corrente e birra alla spina.",
    "Se Garibaldi è partito da Quarto, chi erano i tre partiti prima di lui?",
    "Ogni mattina mi alzo e dico 20 volte ciao. Dicono che sia salutare.",
    "Hanno rubato un tir pieno di lampadine. La polizia brancola nel buio.",
    "Il cognac al caffè: “Non mi sembri molto corretto!”",
    "Quand'ero piccolo i miei genitori hanno cambiato casa una decina di volte. Ma io sono sempre riuscito a trovarli…",
    "Come dicono le galline: 'se beccamo domani'.",
    "Ho inventato un cappotto che va bene per tutte le stagioni; quando fa caldo basta toglierlo.",
    "Il mio commercialista oggi era molto triste. È partita Iva.",
    "Due casseforti si incontrano per strada. Che combinazione!",
    "Evade pericoloso galeotto: la polizia rastrella le campagne. I contadini ringraziano.",
    "Un uomo entra in un caffè. Splash!",
    "Le vie del Signore sono infinite. È la segnaletica che lascia a desiderare.",
    "Il muto dice al sordo: 'Il cieco ci sta spiando!'",
    "I videogame mi hanno rovinato la vita. Per fortuna ne ho altre due!",
    "Chi sale sull'autobus col mal di testa deve pagare due biglietti. Il mal di testa è passeggero.",
    "Due candele hanno un incidente, una dice all'altra: 'ma non l'hai visto lo stoppino?'",
    "Quando un australiano compra un boomerang nuovo, come fa a buttare via quello vecchio?",
    "La lana di vetro si fa con le pecore di Murano?",
    "L'equinozio? Un cavallo fannullone?",
    "Un birillo e una cartolina andavano a scuola, ma l'uno veniva sempre bocciato e l'altra sempre rimandata.",
    "'Non so se mi spiego...' disse il paracadute.",
    "'Non so se mi spiedo...' disse un pollo sul girarrosto.",
    "Il marinaio spiegò le vele al vento, ma il vento non capì...",
    "Il deserto del Sahara è in Africa. Su questo non ci piove.",
    "Cosa dice uno spaventapasseri bugiardo? Dice le balle di fieno!",
    "Se il cane ringhia, la ringhiera abbaia?! ",
    "Perché le tende piangono? Perché sono da sole."
  ]

  export const scioglilingua = [
    "Trentatré trentini entrarono a Trento, tutti e trentatré trotterellando.",
    "Li vuoi quei kiwi? E se non vuoi quei kiwi che kiwi vuoi?",
    "Treno troppo stretto e troppo stracco stracca troppi storpi e stroppia troppo",
    "Una platessa lessa lesse la esse di Lassie su un calesse fesso.",
    "Ma fossi tu quel barbaro barbiere che barbassi quella barba così barbaramente a piazza Barberini.",
    "Se oggi seren non è, doman seren sarà, se non sarà seren si rasserenerà.",
    "Una rana nera e rara sulla rena errò una sera.",
    "Assolto in assise l'assassino dell'assessore di Frasassi in missione segreta a Sassari.",
    "Eva dava l'uva ad Ava, Ava dava le uova ad Eva, ora Eva è priva d'uva mentre Ava è priva d'uova.",
    "Sul tagliere gli agli taglia non tagliare la tovaglia la tovaglia non è aglio se la tagli fai uno sbaglio.",
    "Caro conte chi ti canta tanto canta che t'incanta.",
    "Chi ama chiama chi ama, chiamami, tu che chi ami chiami. Chi amo chiamerò se tu non chiami.",
    "Sa chi sa se sa chi sa che se sa non sa se sa, sol chi sa che nulla sa ne sa più di chi ne sa.",
    "Sopra la panca la capra campa, sotto la panca la capra crepa.",
    "Sotto un cespo di rose scarlatte offre il rospo té caldo con latte. Sotto un cespo di rose paonazze tocca al rospo sciacquare le tazze.",
    "Quanti rami di rovere roderebbe un roditore se un roditore potesse rodere rami di rovere?",
    "Il cuoco cuoce in cucina e dice che la cuoca giace e tace perché sua cugina non dica che le piace cuocere in cucina col cuoco.",
    "A che serve che la serva si conservi la conserva se la serva quando serve non si serve di conserva?",
    "Porta aperta per chi porta, chi non porta parta pure per la porta aperta, poco importa.",
    "Mi attacchi i tacchi tu che attacchi i tacchi? Io attaccarti i tacchi a te? Attaccati te i tuoi tacchi tu che attacchi i tacchi!",
    "Tigre contro tigre",
    "Tre tigri contro tre tigri",
    "Apelle figlio d'Apollo fece una palla di pelle di pollo, tutti i pesci vennero a galla per vedere la palla di pelle di pollo fatta da Apelle figlio d'Apollo.",
    "Sono un setaccia sassi, ho un setaccio di sassi setacciati e un setaccio di sassi non setacciati, perché sono un setaccia sassi.",
    "Figlia, sfoglia la foglia sfoglia la foglia, figlia.",
    "Il Papa pesa e pesta il pepe a Pisa, Pisa pesa e pesta il pepe al Papa",
    "Al solstizio il Sol sta, sol stando; sol stando il Sol sta al solstizio!",
    "Porte aperte per chi porta; chi non porta parta, pur che non importa aprir la porta.",
    "Se l'arcivescovo di Costantinopoli si volesse disarcivescostantinopolizzare vi disarcivescostantinopolizzereste anche voi per disarcivescostantinopolizzare l'arcivescovo di Costantinopoli?",
    "Due tazze strette in due strette tazze",
    "Tre stecchi secchi in tre strette tasche stanno",
    "Sopra quattro rossi sassi quattro grossi gatti rossi.",
    "Dentro quel palazzo c'è un povero cane pazzo, date un pezzo di pane a quel povero pazzo cane.",
    "Sopra quattro rossi sassi quattro grossi gatti rossi.",
    "Stanno stretti sotto i letti sette spettri a denti stretti."
  ]

  export const proverbi = [
    "A caval donato non si guarda in bocca!",
    "A buon intenditor, poche parole!",
    "Chi dorme non piglia pesci!",
    "Il lupo perde il pelo ma non il vizio!",
    "La gatta frettolosa fa i gattini ciechi!",
    "Non è tutto oro quello che luccica!",
    "Rosso di sera, bel tempo si spera!",
    "Al contadin non far sapere quanto è buono il formaggio con le pere!",
    "Anche l'occhio vuole la sua parte!",
    "Chi non risica non rosica!",
    "Soli non si starebbe bene nemmeno in Paradiso!",
    "L'appetito vien mangiando!",
    "Il mondo è fatto a scale. C'è chi scende e c'è chi sale!",
    "Quando c'è la salute c'è tutto!",
    "Non è bello ciò che è bello ma è bello ciò che piace!",
    "Piove sempre sul bagnato!",
    "Non dire gatto se non ce l'hai nel sacco!",
    "Il lupo non è così brutto come lo si dipinge.",
    "Can che abbaia non morde!",
    "Chi la dura la vince!",
    "Buon sangue non mente!",
    "A goccia a goccia si scava la roccia!",
    "Siamo a cavallo!",
    "Gallina che canta ha fatto l'uovo!",
    "Non c'è trippa per gatti!",
    "Il mondo è bello perchè è avariato!",
    "Non è bello ciò che è bello, figuriamoci ciò che è brutto!",
    "Errare humanum est, perseverare ovest!"
  ]

  export default {
    data() {
      return {}
    }
  }
</script>
