<template>
  <div class="about">
    <h1>This is a Tag page</h1>
    Can you can a can as a canner can can a can? She sells seashells on the seashore. The shells she sells are seashells, she's sure. I saw a kitten eating chicken in the kitchen.
    How many cookies could a good cook cook If a good cook could cook cookies? A good cook could cook as much cookies as a good cook who could cook cookies. A fat thrush flies through thick fog.
    The sixth sick sheikh's sixth sheep's sick. Three grey geese in the green grass grazing. Grey were the geese and green was the grass.
    I thought a thought. But the thought I thought wasn't the thought I thought I thought. If the thought I thought I thought had been the thought I thought, I wouldn’t have thought so much.
    Pad kid poured curd pulled cod. Double bubble gum, bubbles double. Tom threw Tim three thumbtacks.
    How much wood would a woodchuck chuck if a woodchuck would chuck wood? A woodchuck would chuck how much a woodchuck would chuck if a woodchuck would chuck wood.
    Betty Botter bought some butter but, said she, the butter's bitter. If I put it in my batter, it will make my batter bitter. But a bit of better butter will make my bitter batter better. So she bought some better butter, better than the bitter butter, put it in her bitter batter, made her bitter batter better. So it was better Betty Botter bought some better butter.
  </div>
</template>
