<template>
  <div class="faq">
    <div style="width: 100%; text-align: center;">
      <h1>Frequently Asked Questions:</h1>
      <h2>Le risposte a tutte le domande che stai cercando!</h2>
    </div>
    <br>
    <table style="width: 100%; text-align:center">
      <tr>
        <td><h3>Scarica la documentazione:</h3></td>
        <td style="text-align:left"><Button label="Marlin Guida PDF" @click="rickRoll" icon="pi pi-download" rounded/></td>
        <td></td>
        <td><h3>Guarda i video tutorial:</h3></td>
        <td style="text-align:left"><Button label="Marlin Guida Video" @click="rickRoll" icon="pi pi-play" rounded/></td>
      </tr>
    </table>
    <br>
    <table style="width: 100%; table-layout: fixed;">
      <tr>
        <td>
          <Panel header="Cosa è Marlin?" toggleable>
            Marlin è un pesce.
          </Panel>
        </td>
        <td>
          <Panel header="Come funziona Marlin?" toggleable>
            E' al di fuori delle nostre competenze.
          </Panel>
        </td>
        <td>
          <Panel header="Come mai è così lento?" toggleable>
            Mai pensato di farti gli affari <b>tuoi</b>?
          </Panel>
        </td>
      </tr>
      <br>
      <tr>
        <td>
          <Panel header="Posso mangiare Marlin?" toggleable>
            No, cioè, sì, puoi mangiare i marlin ma non puoi mangiare Marlin.
          </Panel>
        </td>
        <td>
          <Panel header="Ci sono alternative a Marlin?" toggleable>
            NO rimarrai bloccato qui per sempre <b>MUAHAHAHAHAHHA</b>
          </Panel>
        </td>
        <td>
          <Panel header="Posso sposare Marlin?" toggleable>
            Come scusa?
          </Panel>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
  export default {
    methods : {
        rickRoll() {
            window.open("https://www.youtube.com/watch?v=dQw4w9WgXcQ");
        }
    }
  }
</script>
