<template>
  <div  v-if="this.loggedIn==true">
    <router-link to="/"><img src='../src/assets/marlin.png' class='nav' alt="marlin logo" width="41" height="41" id="logo"></router-link>
    <nav>
      <ul class="navbar">
        <li><router-link to="/">Tutti i Prodotti</router-link></li>
        <li><router-link :to="{ name: 'Variazioni'}">Variazioni</router-link></li>
        <li class="dropdown">
          <router-link :to="{ name: 'Categorie'}">Categorie</router-link>
          <div class="dropdown-content">
            <router-link to="/">Tutti i Prodotti</router-link>
            <router-link :to="{ name: 'Tag'}">Tag</router-link>
            <router-link :to="{ name: 'FAQ'}">FAQ</router-link>
          </div>
        </li>
        <li><router-link :to="{ name: 'Tag'}">Tag</router-link></li>
        <li><router-link :to="{ name: 'FAQ'}">FAQ</router-link></li>
      </ul>
    </nav>
    <router-view/>
  </div>

  <div>
    <p v-if="isMobile">
      <Dialog v-model:visible="mobileModalVisible" modal header="Stai usando un cellulare?" :style="{ width: '100%' }">
          <p>
            <b style="font-size: larger;">Marlin</b> non è ottimizzato per i sistemi mobile, quindi ti consigliamo <b>vivamente</b> di collegarti da un computer.
          </p>
          <template #footer>
            <Button label="Comprendo che da mobile il servizio non è ottimizzato e confermo che voglio accedere al pannello" severity="warning" @click="mobileModalVisible=false" icon="pi pi-exclamation-circle" id="bottoniStart" rounded />
          </template>
      </Dialog>
    </p>
  </div>
  <div v-if="this.loggedIn==false">
    <br><br><br><br><br>
    <img src="./assets/marlin.png" alt="logo" width="200" style="display: block; margin-left: auto; margin-right: auto;">
    <br><br>
    <h2 style="text-align: center;">Entra nel tuo pannello <b style="font-size:xx-large;">Marlin!</b></h2>
    <br>
    <table class="login-form">
      <tr>
        <td><h3>Email: </h3></td>
        <td><InputText v-model="this.username" type="text" class="p-inputtext-sm full-width" size="large"/></td>
      </tr>
      <tr>
        <td><h3>Password: </h3></td>
        <td><Password v-model="this.password" :feedback="false" toggleMask/></td>
      </tr>
    </table>
    <div style="text-align: center;" size="large">
      <Button label="Login!" @click="loggedIn=true" icon="pi pi-user" rounded />
    </div>
  </div>
</template>

<script>
  import axios from "axios";

  export default {
    name: "App",
    data() {
    return {
        username: '',
        password: '',
        loggedIn: false,
        isMobile: false,
        mobileModalVisible: false,
      }
    },

    methods: {
      async login() {
        try {
          const response = await axios.post('http://localhost:3000/login', {
            username: this.username,
            password: this.password
          });

          this.loggedIn = response.data.loggedIn;

          if (!this.loggedIn) {
            alert('Invalid credentials');
          }
        } catch (error) {
          console.error('Error:', error);
        }
      },
      checkMobile() {
        this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
        this.mobileModalVisible = true
      }
    },

    mounted() {
      this.checkMobile();
    }
  }
</script>

<style>
  @import "./style.css";
</style>
