import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'tabulator-tables/dist/css/tabulator_semanticui.min.css';

import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import SplitButton from 'primevue/splitbutton';
import ProgressSpinner from 'primevue/progressspinner';
import Dialog from 'primevue/dialog';
import Toolbar from 'primevue/toolbar';
import DeferredContent from 'primevue/deferredcontent';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Tooltip from 'primevue/tooltip';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Panel from 'primevue/panel';
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Editor from 'primevue/editor';
import "primevue/resources/themes/lara-light-teal/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css"

const app = createApp(App);
app.use(PrimeVue, { ripple: true });
app.component('Button', Button)
app.component('SplitButton', SplitButton)
app.component('ProgressSpinner', ProgressSpinner);
app.component('Dialog', Dialog);
app.component('Toolbar', Toolbar);
app.component('DeferredContent', DeferredContent);
app.component('InputNumber', InputNumber);
app.component('InputText', InputText);
app.component('Password', Password);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Panel', Panel);
app.component('Checkbox', Checkbox);
app.component('Dropdown', Dropdown);
app.component('Calendar', Calendar);
app.component('Editor', Editor);
app.directive('Tooltip', Tooltip);

app.use(router).mount('#app')
