<template>
  <div id="main">
    <div class="notFound">
      <div>
        <router-link to="/"><img src="../assets/marlin.png" alt="logo" width="200"></router-link>
      </div>
      <h1>Errore 404</h1>
      <h2>La pagina non esiste oppure è stata spostata</h2>
      <br>
      <a href="http://localhost:8080/">
        <Button label="Torna alla Home" @click="" icon="pi pi-home" rounded />
      </a>
    </div>
  </div>
</template>
<style>
  #main{
    display: table;
    width: 100%;
    height: 65vh;
    text-align: center;
  }

  .notFound{
    display: table-cell;
    vertical-align: middle;
  }

  .notFound h1{
      font-size: 50px;
      display: inline-block;
      padding-right: 12px;
      animation: type .5s alternate infinite;
  }

  @keyframes type{
      from{box-shadow: inset -3px 0px 0px #175257;}
      to{box-shadow: inset -3px 0px 0px transparent;}
  }
</style>
