<template>
  <body>
    <Toolbar id="toolbarTop">
      <template #start>
        <Button label="Nuovo Prodotto" @click="modalInsVisible=true" icon="pi pi-plus" id="bottoniStart" rounded />
        <Button label="Elimina Prodotto" @click="prepareDelete" icon="pi pi-trash" id="bottoniStart" rounded :disabled="!fetched" />
        <Button label="Modifica Massiva" @click="prepareMod" icon="pi pi-pencil" id="bottoniStart" rounded :disabled="!fetched" />
      </template>
      <template #center>
        <Button @click="firstPage" style="width: 39px; height: 39px" icon="pi pi-angle-double-left" id="bottoniCenterLeft" rounded :disabled="!fetched || currPage == 1"/>
        <Button @click="prevPage" icon="pi pi-angle-left" id="bottoniCenterLeft" rounded :disabled="!fetched || currPage == 1"/>
        <table>
          <tr class="firstRowSearch">
            <Button @click="inputPage" icon="pi pi-search" id="searchPageButton" rounded :disabled="!fetched" />
            <InputNumber v-model="currPage" inputId="minmax" :min="1" :max="table.totalPages" id="searchPage" />
            <b id="totalPages"> / {{ table.totalPages }}</b>
          </tr>
          <tr class="secondRowSearch"><b>{{ firstProduct }}-{{ lastProduct }} / {{ table.totalProducts }}</b></tr>
        </table>
        <Button @click="nextPage" icon="pi pi-angle-right" id="bottoniCenterRight" rounded :disabled="!fetched || this.currPage == this.table.totalPages" />
        <Button @click="lastPage" style="width: 39px; height: 39px" icon="pi pi-angle-double-right" id="bottoniCenterRight" rounded :disabled="!fetched || this.currPage == this.table.totalPages" />
      </template>
      <template #end>
        <Button label="Importa CSV" @click="" icon="pi pi-file" id="bottoniEnd" rounded :disabled="!fetched" />
        <SplitButton label="Scarica CSV" @click="this.table.container.download('csv', 'Prodotti.csv')" :model="itemsDownload" icon="pi pi-download" id="bottoniEnd" rounded :disabled="!fetched" />
        <Button label="Stampa" @click="this.table.container.print('active', true)" icon="pi pi-print" id="bottoniEnd" styleClass="marlinColors" rounded :disabled="!fetched" />
        <Button v-tooltip.bottom="'Ultimo aggiornamento di questa pagina: \n'+table.lastUpdate" type="text" placeholder="Ultimo aggiornamento" label="Aggiorna" icon="pi pi-replay" @click="this.$router.go()" id="bottoniEnd" rounded />
      </template>
    </Toolbar>

    <Dialog v-model:visible="modalDelVisible" modal header="Confermi di voler eliminare questi prodotti?" :style="{ width: '35vw' }">
      <b v-if="table.selectedRow.length==0">Non hai selezionato prodotti da eliminare!</b>
      <div v-else>
        <b>{{ table.selectedRow.length }} prodotti selezionati</b>
        <ul>
          <li v-for="row in table.selectedRow">
            <div>{{ row.id }} - {{ row.sku }} - {{ row.name }}</div>
          </li>
        </ul>
      </div>
      <template #footer>
        <Button label="Indietro" @click="modalDelVisible=false" icon="pi pi-arrow-down-left" id="bottoniStart" rounded />
        <Button label="Elimina" severity="danger" @click="loopDelete" icon="pi pi-trash" id="bottoniStart" rounded :disabled="table.selectedRow.length==0" />
      </template>
    </Dialog>
    <Dialog v-model:visible="modalInsVisible" modal header="Inserisci i dettagli del nuovo prodotto" :style="{ width: '60vw' }">
      <b>I campi segnati con * sono obbligatori!</b> <br>
      <b style="color:red">{{ errors.insert }}</b>
      <br><br>
      <Panel header="Informazioni Base" toggleable>
        <table class="betterTable">
          <tr>
            <td><h4>SKU*</h4></td><td><InputText type="text" v-model="productJson.sku" class="p-inputtext-sm"/></td>
            <td><h4>Nome*</h4></td><td><InputText type="text" v-model="productJson.name" class="p-inputtext-sm"/></td>
            <td><h4>Prezzo Listino*</h4></td><td><span class="p-input-icon-left"><i class="pi pi-euro" /><InputText type="number" v-model="productJson.regular_price" class="p-inputtext-sm"/></span></td>
          </tr>
        </table>
        <table class="betterTable">
          <tr>
            <td><h4>Tipo*</h4></td><td><Dropdown v-model="productJson.type" editable :options="types" optionLabel="name" placeholder="Seleziona" /></td>
            <td><h4>Stato*</h4></td><td><Dropdown v-model="productJson.status" editable :options="statuses" optionLabel="name" placeholder="Seleziona" /></td>
          </tr>
        </table>
      </Panel><br>
      <Panel header="Avanzate" toggleable :collapsed="true">
        <TabView>
          <TabPanel header="Catalogazione">Categorie / tag / immagini</TabPanel>
          <TabPanel header="Offerta" v-model="saleTab">
            <table class="betterTable">
              <td><h4>Prezzo Offerta</h4></td><td><span class="p-input-icon-left"><i class="pi pi-euro" /><InputText type="number" v-model="productJson.sale_price" class="p-inputtext-sm"/></span></td>
              <td><h4>Inizio</h4></td><td><Calendar v-model="productJson.date_on_sale_from" showIcon dateFormat="yy-mm-dd"/></td>
              <td><h4>Fine</h4></td><td><Calendar v-model="productJson.date_on_sale_to" showIcon dateFormat="yy-mm-dd"/></td>
            </table>
          </TabPanel>
          <TabPanel header="Specifiche">
            <table class="betterTable">
              <tr>
                <td><h4>Virtuale</h4></td><td><Checkbox v-model="productJson.virtual" :binary="true" /></td>
                <td><h4>Scaricabile</h4></td><td><Checkbox v-model="productJson.downloadable" :binary="true" /></td>
                <td><h4>In evidenza</h4></td><td><Checkbox v-model="productJson.featured" :binary="true" /></td>
              </tr>
            </table>
            <table class="betterTable">
              <tr>
                <td><h4>Visibilità Catalogo</h4></td><td><Dropdown v-model="productJson.catalog_visibility" editable :options="visCatalog" optionLabel="name" placeholder="Seleziona" /></td>
                <td><h4>Magazzino</h4></td><td style="text-align:left"><Dropdown v-model="productJson.stock_status" editable :options="stock" optionLabel="name" placeholder="Seleziona" /></td>
                <td><h4>Peso (Kg)</h4></td><td style="text-align:center"><InputText type="number" v-model="productJson.weight" class="p-inputtext-sm"/></td>
              </tr>
              <tr>
                <td><h4>Lunghezza (cm)</h4></td><td><InputText type="number" v-model="productJson.dimensions.length" class="p-inputtext-sm"/></td>
                <td><h4>Larghezza (cm)</h4></td><td><InputText type="number" v-model="productJson.dimensions.width" class="p-inputtext-sm"/></td>
                <td><h4>Altezza (cm)</h4></td><td><InputText type="number" v-model="productJson.dimensions.height" class="p-inputtext-sm"/></td>
              </tr>
            </table>
          </TabPanel>
          <TabPanel header="Descrizioni">
            <table  class="betterTable" style="width: 100%; table-layout: fixed; vertical-align: top">
              <tr>
                <td><h3>Descrizione Breve</h3></td>
                <td><h3>Descrizione Lunga</h3></td>
              </tr>
              <tr>
                <td><Editor v-model="productJson.short_description" >
                  <template v-slot:toolbar>
                    <span class="ql-formats">
                      <button v-tooltip.bottom="'Grassetto'" class="ql-bold"></button>
                      <button v-tooltip.bottom="'Corsivo'" class="ql-italic"></button>
                      <button v-tooltip.bottom="'Sottolinea'" class="ql-underline"></button>
                      <button v-tooltip.bottom="'Barrato'" class="ql-strike"></button>
                      <button v-tooltip.bottom="'Collegamento'" class="ql-link"></button>
                    </span>
                  </template>
                </Editor></td>
                <td><Editor v-model="productJson.description" >
                  <template v-slot:toolbar>
                    <span class="ql-formats">
                      <button v-tooltip.bottom="'Grassetto'" class="ql-bold"></button>
                      <button v-tooltip.bottom="'Corsivo'" class="ql-italic"></button>
                      <button v-tooltip.bottom="'Sottolinea'" class="ql-underline"></button>
                      <button v-tooltip.bottom="'Barrato'" class="ql-strike"></button>
                      <button v-tooltip.bottom="'Collegamento'" class="ql-link"></button>
                    </span>
                  </template>
                </Editor></td>
              </tr>
            </table>
          </TabPanel>
          <TabPanel header="Variazioni">robe sulla varianti di cui ho molta paura</TabPanel>
        </TabView>
      </Panel><br>
      <Panel header="Coda di inserimento" toggleable :collapsed="true">
        <b v-if="queueProducts.length==0">Non sono presenti prodotti nella coda di inserimento!</b>
        <div v-else>
          <b>{{ queueProducts.length }} prodotti in coda di inserimento</b>
          <ul>
            <li v-for="row in queueProducts">
              <div>{{ row.id }} - {{ row.sku }} - {{ row.name }}</div>
            </li>
          </ul>
        </div>
      </Panel>
      <template #footer>
        <Button label="Indietro" @click="modalInsVisible=false" icon="pi pi-arrow-down-left" id="bottoniStart" rounded />
        <Button label="Aggiungi in coda" severity="warning" @click="queueNewProduct" icon="pi pi-bars" id="bottoniStart" rounded />
        <Button label="Inserisci" severity="success" @click="newProduct" icon="pi pi-plus" id="bottoniStart" rounded />
      </template>
    </Dialog>

    <Dialog v-model:visible="modalModVisible" modal header="Modifica più prodotti contemporaneamente" :style="{ width: '35vw' }">
      <TabView>
        <TabPanel header="Scrivi Query">
          Scemo...
        </TabPanel>
        <TabPanel header="Righe Selezionate" :disabled="table.selectedRow.length==0">
          ...chi legge!
        </TabPanel>
      </TabView>
      <template #footer>
        <Button label="Indietro" @click="modalModVisible=false" icon="pi pi-arrow-down-left" id="bottoniStart" rounded />
        <Button label="Modifica" severity="warning" @click="" icon="pi pi-pencil" id="bottoniStart" rounded />
      </template>
    </Dialog>

    <div class="loader" v-if="!apiData.pageData">
      <h1>Caricamento prodotti...</h1>
    </div>
    <div class="loader" v-if="loopingFetch && apiData.pageData && !loopingDelete">
      <h1>Prodotti caricati: {{ firstProduct }}-{{ lastProduct }} / {{ table.totalProducts }}</h1>
      <h1>Pagine caricate: {{ currPage }} / {{ table.totalPages }}</h1>
    </div>
    <div class="loader" v-if="loopingDelete">
      <h1>Eliminando prodotti...</h1>
      <h1>Prodotti eliminati: {{ rowDelIter + 1 }} / {{ table.selectedRow.length }}</h1>
    </div>
    <div class="loader" v-if="!apiData.pageData || loopingFetch && apiData.pageData || loopingDelete">
      <ProgressSpinner style="width: 60px" strokeWidth="8" animationDuration="1.5s" aria-label="Loading Spinner" />
      <br><br><br><br><br><br><br><br>
      <h3>{{ loadPhrase }}</h3>
    </div>
    <div v-show="fetched && apiData.fullData && loopingDelete==false">
      <div id="table"></div>
    </div>
  </body>
</template>

<script>
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { funFacts, funnyPhrases, barzellette, scioglilingua, proverbi } from '../components/LoadingPhrases.vue'

export default {
  name: 'HomeView',
  data() {
    return {
      apiData: {
        pageData: null,                                       //ogni iterazione di fetching
        fullData: null                                      //il catalogo completo
      },
      cred: {
        user: 'ck_45e1e420df0cccb8db48dbca03056fee58290ecb',  //token username
        pass: 'cs_b210543b7b87850ca98bfbc809a2ad2dc9ac57cf'  //token password
      },
      fetchResponse: null,                                       //risposta raw dell'api
      table: {
        container: null,
        selectedRow: null,
        lastUpdate: null,
        totalProducts: null,
        totalPages: null,
        perPage: 100                                        //numero di prodotti per pagina
      },
      currPage: 1,                                          //pagina corrente
      firstProduct: 1,
      lastProduct: 100,
      fetched: false,                                       //ha completato il caricamento dei prodotti?
      loopingFetch: true,                                   //sta ciclando per prendere tutti i prodotti?
      loopingDelete: false,                                  //sta ciclando per cancellare tutti i prodotti selezionati
      rowDelIter: 0,
      loadPhrase: null,                                     //frasettina carina di caricamento così gli utenti non si annoiano
      delayPhrase: null,                                    //serve per generare una frase ogni tot sec
      modalDelVisible: false,
      modalInsVisible: false,
      modalModVisible: false,
      dateStartSale: null,
      dateEndSale: null,
      publishDate: null,
      selectedVisCat: null,
      //NOTE - i nomi di queste variabili sono esattamente come i valori nell'api, se sono diversi non verranno associati. NON MODIFICARE
      productJson: {
        name: '',
        //date_created: '',   //NOTE - niente programmazione futura, non cambia in automatico lo status e diventa confusionario
        type: '',
        status: '',
        featured: false,
        catalog_visibility: '',
        description: '',
        short_description: '',
        sku: '',
        regular_price: '',    //FIXME - per i prezzi mettere costrizione solo con il punto.
        sale_price: '',
        date_on_sale_from: '',
        date_on_sale_to: '',
        virtual: false,
        downloadable: false,
        weight: '',
        dimensions: {       //FIXME - ricordarsi di fare un toString prima di creare il json IMPORTANTE
          length: '',
          width: '',
          height: ''
        },
        stock_status: ''
      },
      submittedData: null,
      queueProducts: [],
      errors: {
        insert: ''
      },
      visCatalog: [
        { name: 'Completa', code: 'visible' },
        { name: 'Solo negozio', code: 'catalog' },
        { name: 'Solo risultati di ricerca', code: 'search' },
        { name: 'Nascosto', code: 'hidden' }
      ],
      types: [
        { name: 'Semplice', code: 'simple' },
        { name: 'Variabile', code: 'variable' }
      ],
      statuses: [
        { name: 'Pubblico', code: 'publish' },
        { name: 'Privato', code: 'private' },
        { name: 'Bozza', code: 'draft' },
        { name: 'In attesa di revisione', code: 'pending' }
      ],
      stock: [
        { name: 'Presente', code: 'instock' },
        { name: 'Assente', code: 'outofstock' },
        { name: 'In arretrato', code: 'onbackorder' }
      ],
      originalSelectValues: [],
      itemsDownload: [
        {
          label: 'XLSX (Pros)',
          command: () => { this.table.container.download("xlsx", "Prodotti.xlsx", {sheetName: "Prodotti"}) } /*FIXME - RICHIEDE LIBRERIA SHEETJS (https://docs.sheetjs.com/docs/)*/
        },
        {
          label: 'PDF (Pros)',
          command: () => { this.table.container.downloadToTab("pdf") } /*FIXME - RICHIEDE LIBRERIE jsPDF E jsPDF-AutoTable Plugin*/
        },
        {
          label: 'JSON',
          command: () => { this.table.container.download("json", "Prodotti.json") }
        },
        {
          label: 'HTML',
          command: () => { this.table.container.download("html", "Prodotti.html", { style: true }) }
        }
      ]
    };
  },

  watch:{
    currPage(_newPage, oldPage){               //c'è un '_' davanti per rendere "intenzionale" il fatto che le variabili poi non vengono usate
      if (this.currPage){
        this.lastProduct = this.currPage * 100
        this.firstProduct = this.lastProduct - 100 + 1    //lo so che sarebbe -99, ma così ha più logicamente senso: -100 dall'ultimo prodotto e +1 perchè la tabella non parte da 0, shut up
        if(this.currPage == this.table.totalPages){
          this.lastProduct = this.table.totalProducts
        }
      }else{
        this.currPage = oldPage
      }
    }
  },

  methods: {
    loopPhrase() {
      this.delayPhrase = setInterval(4500)
      clearInterval(this.delayPhrase)
      if(this.loopingFetch){
        this.delayPhrase = setInterval(() => { this.randomPhrase() }, 4500)
      }
    },
    randomPhrase() {
      let dice = Math.floor(Math.random() * 5) + 1;
      switch (dice) {
        case 1:
          this.loadPhrase = funnyPhrases[Math.floor(Math.random() * funnyPhrases.length) + 1]
          break
        case 2:
          this.loadPhrase = funFacts[Math.floor(Math.random() * funFacts.length) + 1]
          break
        case 3:
          this.loadPhrase = barzellette[Math.floor(Math.random() * barzellette.length) + 1]
          break
        case 4:
          this.loadPhrase = scioglilingua[Math.floor(Math.random() * scioglilingua.length) + 1]
          break
        case 5:
          this.loadPhrase = proverbi[Math.floor(Math.random() * proverbi.length) + 1]
          break
        default:
          this.loadPhrase = "Caricamento..."
      }
    },

    async fetchData() {
      this.fetched = false
      try {
        this.fetchResponse = await fetch('https://demo3.asmartbox.com/wp-json/wc/v3/products?per_page=' + this.table.perPage + '&page=' + this.currPage, {
          method: 'get',
          headers: {
            'Authorization': 'Basic ' + window.btoa(`${this.cred.user}:${this.cred.pass}`)
          }
        })
        this.apiData.pageData = await this.fetchResponse.json()
      } catch (err) {
        console.error(err)
      }
    },
    async loopFetching(){
      this.loopingFetch = true
      for(this.currPage; this.currPage <= this.table.totalPages; this.currPage++){
        await this.fetchData()
        if(!this.apiData.fullData){
          this.apiData.fullData = this.apiData.pageData
        }else{
          this.apiData.fullData = this.apiData.fullData.concat(this.apiData.pageData)
        }
      }
      this.currPage = 1
      this.loopingFetch = false;
    },

    prepareDelete() {
      this.table.selectedRow = this.table.container.getSelectedData();
      this.modalDelVisible = true
      this.randomPhrase()
      this.loopPhrase()
    },
    async deleteRow() {
      try {
        this.fetchResponse = await fetch('https://demo3.asmartbox.com/wp-json/wc/v3/products/' + this.table.selectedRow[this.rowDelIter].id, {
          method: 'delete',
          headers: {
            'Authorization': 'Basic ' + window.btoa(`${this.cred.user}:${this.cred.pass}`)
          }
        })
      } catch (err) {
        console.error(err)
      }
    },
    async loopDelete(){
      this.modalDelVisible = false
      this.loopingDelete = true
      for(this.rowDelIter; this.rowDelIter <= this.table.selectedRow.length; this.rowDelIter++){
        await this.deleteRow()
      }
      clearInterval(this.delayPhrase)
      this.loopingDelete = false;
      this.$router.go()
    },

    async prepareNewProduct() {
      try {
        console.log("pre data")
      if(this.productJson.date_on_sale_from && this.productJson.date_on_sale_to){
        let isoStringDate = this.productJson.date_on_sale_from.toISOString()
        this.productJson.date_on_sale_from = isoStringDate.slice(0, 10) + "T00:00:00"
        isoStringDate = this.productJson.date_on_sale_to.toISOString()
        this.productJson.date_on_sale_to = isoStringDate.slice(0, 10) + "T00:00:00"
      }
      console.log("post data")
      if(this.queueNewProduct.length > 0){
        await this.reSetSelectValues("reset")
      }
      this.productJson.type = this.productJson.type.code
      this.productJson.status = this.productJson.status.code
      this.productJson.catalog_visibility = this.productJson.catalog_visibility.code
      this.productJson.stock_status = this.productJson.stock_status.code
      this.submittedData = JSON.stringify(this.productJson)
      } catch (err) {
          console.log(err)
      }
    },
    async queueNewProduct(){
      console.log("pre prepare")
      await this.prepareNewProduct()
      console.log("post prepare")
      this.queueProducts.push(this.submittedData)
      console.log("post push")
      console.log(this.submittedData)
      console.log(this.queueProducts)
    },
    async newProduct(){
      if(this.queueProducts.length == 0){
        this.errors.insert = "ATTENZIONE: Non hai inserito nessuna informazione del prodotto!"
        return false
      }
      await this.prepareNewProduct()
      try {
        this.fetchResponse = await fetch('https://demo3.asmartbox.com/wp-json/wc/v3/products', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + window.btoa(`${this.cred.user}:${this.cred.pass}`)
          },
          body: this.submittedData
        })
      } catch (err) {
        this.errors.insert = err.message
        console.error(err)
      }
    },

    prepareMod() {
      this.table.selectedRow = this.table.container.getSelectedData();
      this.modalModVisible = true
    },

    reSetSelectValues(parameter) {
      if(parameter == "set"){
        this.originalSelectValues[0] = this.visCatalog
        this.originalSelectValues[1] = this.types
        this.originalSelectValues[2] = this.statuses
        this.originalSelectValues[3] = this.stock
        console.log("setted!")
      }else if(parameter == "reset"){
        this.visCatalog = this.originalSelectValues[0]
        this.types = this.originalSelectValues[1]
        this.statuses = this.originalSelectValues[2]
        this.stock = this.originalSelectValues[3]
        console.log("REsetted!")
      }else {console.log("parametro RE/SET non riconosciuto")}
    },

    async settingUp(){
      this.reSetSelectValues("set")
      await this.fetchData()
      this.table.totalProducts = parseInt(this.fetchResponse.headers.get('X-WP-Total'), 10)
      if (this.table.totalProducts < 100){
        this.lastProduct = this.table.totalProducts
      }
      this.table.totalPages = this.fetchResponse.headers.get('X-WP-TotalPages')
      await this.loopFetching()
      this.createTable();
      this.fetched = true
      clearInterval(this.delayPhrase)
      this.table.lastUpdate = new Date()
    },

    //CREAZIONE E CONFIGURAZIONE TABELLA A PARTIRE DAL FILE JSON GENERATO DALL'API
    createTable() {
      let dropdownSale = [
        { value: '', label: '‎' },
        { value: 'true', label: 'Sì ✔️' },
        { value: 'false', label: 'No ❌' }
      ]
      let dropdownStock = [
        { value: '', label: '‎' },
        { value: 'instock', label: 'Sì ✔️' },
        { value: 'outofstock', label: 'No ❌' },
        { value: 'onbackorder', label: 'Arretrato ❕' }
      ]
      let dropdownType = [
        { value: '', label: '‎' },
        { value: 'simple', label: 'Semplice' },
        { value: 'variable', label: 'Variabile' }
      ]
      //no password protected, non è gestito dal json ma sembra dal sistema a livello server
      let dropdownStatus = [
        { value: '', label: '‎' },
        { value: 'publish', label: 'Pubblico' },
        { value: 'private', label: 'Privato' },
        { value: 'draft', label: 'Bozza' },
        { value: 'pending', label: 'In revisione' }
      ]
      function dropdownHeaderFilter(headerValue, filterParams) {
        return headerValue === filterParams.value;
      }

      this.table.container = new Tabulator('#table', {
        data: this.apiData.fullData,
        pagination:true,
        paginationSize: this.table.perPage,
        paginationInitialPage: 1,
        placeholder: "Non sono stati trovati prodotti.",
        keybindings: true,
        clipboard: true,
        downloadConfig: true,
        downloadRowRange: true,
        addRowPos: 'top',
        resizableRows: false,
        resizableColumns: false,
        printHeader: "<h1>Tabella dei Prodotti</h1>",
        printAsHtml: true,
        printStyled: true,
        //rowHeight: 60,
        layout: "fitColumns",
        autoColumns: false,
        rowFormatter(row) {
          if (row.getPosition() % 2 === 0) {
            row.getElement().classList.add("even-row");
          }
        },
        columns: [
          { formatter: "rowSelection", titleFormatter: "rowSelection", hozAlign: "center", frozen: true, headerSort: false, print: false, width: "0px" },
          { title: 'SKU', field: 'sku', headerFilter: true, frozen: true, headerTooltip: 'Codice del prodotto', resizable: false },
          { title: 'Nome', field: 'name', headerFilter: true, frozen: true, headerTooltip: 'Nome del prodotto', resizable: false },
          { title: '€', field: 'regular_price', headerFilter: true, headerTooltip: 'Prezzo regolare', hozAlign: "center", resizable: false, formatter: "money", formatterParams: {
              decimal: ".",
              thousand: ",",
              symbol: "€"
            }
          },
          { title: '€ scontato', field: 'sale_price', headerFilter: true, headerTooltip: 'Prezzo in offerta', hozAlign: "center", resizable: false, formatter: "money", formatterParams: {
              decimal: ".",
              thousand: ",",
              symbol: "€"
            }
          },
          { title: 'Offerta?', field: 'on_sale', headerFilter: "list", headerFilterParams: {
              values: dropdownSale,
              valueField: 'value',
              labelField: 'label',
              headerFilterFunc: dropdownHeaderFilter
            }, headerTooltip: 'sì/no', hozAlign: "center", resizable: false,
            formatter(cell) {
              if (cell.getValue() === true) {
                return "Sì ✔️"
              } else { return '' }
            }
          },
          { title: 'Magazzino?', field: 'stock_status', headerFilter: "list", headerFilterParams: {
              values: dropdownStock,
              valueField: 'value',
              labelField: 'label',
              headerFilterFunc: dropdownHeaderFilter
            }, headerTooltip: 'Disponibilità', hozAlign: "center", resizable: false,
            formatter(cell) {
              if (cell.getValue() === 'instock') {
                return " Sì ✔️";
              } else if (cell.getValue() === 'outofstock') {
                return "No ❌"
              } else { return "Arretrato ❕" }
            }
          },
          { title: 'Tipo', field: 'type', headerFilter: "list", headerFilterParams: {
              values: dropdownType,
              valueField: 'value',
              labelField: 'label',
              headerFilterFunc: dropdownHeaderFilter
            },
            headerTooltip: 'semplice/variabile', hozAlign: "center", resizable: false,
            formatter(cell) {
              if (cell.getValue() === 'variable') {
                return "Variabile";
              } else { return "Semplice" }
            }
          },
          { title: 'Stato', field: 'status', headerFilter: "list", headerFilterParams: {
              values: dropdownStatus,
              valueField: 'value',
              labelField: 'label',
              headerFilterFunc: dropdownHeaderFilter
            }, headerTooltip: 'privato/pubblico/bozza', hozAlign: "center", resizable: false,
            formatter(cell) {
              if (cell.getValue() === 'publish') {
                return "Pubblico";
              } else if (cell.getValue() === 'private') {
                return "Privato";
              } else if (cell.getValue() === 'pending'){
                return "In revisione";
              } else { return "Bozza"; }
            }
          },
          { title: 'Descrizione Breve', field: 'short_description', headerFilter: true, width: 357, formatter: 'html', headerTooltip: 'Breve descrizione del prodotto', resizable: false,
            formatter(cell) {
              let shortDesc = cell.getValue()
              if (shortDesc.length > 55) {
                shortDesc = shortDesc.substring(0, 53)
                return shortDesc + '...';
              } else { return shortDesc }
            }
          },
          //FIXME - non funziona ricerca
          { title: 'Categoria', field: 'categories', headerFilter: true, headerTooltip: 'Categorie in cui è presente il prodotto', resizable: false,
            formatter(cell) {
              const categories = cell.getValue();
              const categoryNames = categories.map(category => category.name);
              return categoryNames.join(", ");
            }
          },
          //FIXME - non funziona ricerca
          { title: 'Tag', field: 'tags', headerFilter: true, headerTooltip: 'Tag in cui è presente il prodotto', resizable: false,
            formatter(cell) {
              const tags = cell.getValue();
              const tagsNames = tags.map(category => category.name);
              return tagsNames.join(", ");
            }
          },
          { title: 'Immagine', field: 'images', headerTooltip: 'Immagine in evidenza', resizable: false,
              formatter(cell){
                //FIXME - ciuccia un botto di banda perchè la risoluzione delle immagini rimane la stessa, ottimizzare (forse serve php)
                let images = cell.getValue();
                let html = "";
                //NOTE - ABOMINIO IMMANE. Ma è l'unico modo che funziona per far vedere solo il primo elemento dell'array.
                //      No, images[0].src non funziona. No, non so perchè.
                //      No, non funziona neanche for (let i = 0; i < 1; i++). No, non so perchè. Appena metto un num al posto di images.length si distrugge tutto.
                for (let i = 0; i < images.length; i++) {
                  if(i == 0){
                    html += "<DeferredContent @load='onDataLoad'><img src='" + images[i].src + "' height='50'> </DeferredContent>";
                  }
                }
                //NOTE - Nel caso in cui voglia avere più di un immagine per prodotto. Prima fixa problema della risoluzione
                /*for (let i = 0; i < images.length && i < 5; i++) {
                  html += "<DeferredContent @load='onDataLoad' role='region' aria-live='polite' aria-label='Content loaded after page scrolled down'><img src='" + images[i].src + "' width='40'> </DeferredContent>";
                }
                if (images.length > 5) {
                  html += "<b> . . .</b>"
                }*/
                return html;
              }

          }
        ]
      })
    },

    firstPage() {
      this.currPage = 1
      this.table.container.setPage(1)
    },
    prevPage() {
      this.currPage--
      this.table.container.previousPage();
    },
    nextPage() {
      this.currPage++
      this.table.container.nextPage();
    },
    lastPage() {
      this.currPage = this.table.totalPages
      this.table.container.setPage(this.table.totalPages)
    },
    inputPage() {
      if (!this.currPage || this.currPage < 1) {
        this.firstPage()
      }else{
        if(this.currPage > this.table.totalPages) {
          this.lastPage()
        }else{
          this.table.container.setPage(this.currPage)
        }
      }
    }
  },

  mounted() {
    this.randomPhrase()
    this.settingUp()
    this.loopPhrase()
  }
}
</script>
