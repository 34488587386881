import { createRouter, createWebHistory } from 'vue-router'
import Prodotti from '../views/ProdottiView.vue'
import Variazioni from '../views/VariazioniView.vue'
import Categorie from '../views/CategorieView.vue'
import Tag from '../views/TagView.vue'
import Faq from '../views/FaqView.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'Prodotti',
    component: Prodotti
  },
  {
    path: '/variazioni',
    name: 'Variazioni',
    component: Variazioni
  },
  {
    path: '/categorie',
    name: 'Categorie',
    component: Categorie
  },
  {
    path: '/tag',
    name: 'Tag',
    component: Tag
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: Faq
  },
  //catchall 404
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
